import { supabase } from "./supabase";

const path = window.location.pathname;
const isEnglish = path.includes("/en/");

async function getNews() {
  let { data, error } = await supabase
    .from("news")
    .select(
      "id, created_at, title, pdf, number, draft, uuid, date, news_en (pdf)"
    )
    .neq("draft", true)
    .order("number", { ascending: false })
    .limit(1)
    .single();

  if (error) {
    console.log(error);
    return;
  }

  renderNews(data);
}

getNews();

function renderNews(newsData) {
  const container = document.getElementById("container");

  const date = new Date(newsData.date);
  const year = date.getUTCFullYear();

  container.innerHTML += `
    <a href="${
      isEnglish ? newsData.news_en[0].pdf : newsData.pdf
    }" target="_blank" rel="noopener noreferrer"
    class="sm:w-[50%] lg:max-w-md lg:w-auto mx-auto mt-8 flex bg-white border border-black rounded-md hover:scale-[1.03] hover:rotate-[-0.5deg] transition-all duration-500">
    <i class="fa-solid fa-arrow-right self-center py-6 px-4 text-3xl"></i>
    <div class="py-3 pe-4 text-start">
      <h5 class="text-xl md:text-2xl font-semibold text-black font-poppins mb-0">
        ${isEnglish ? "Newest Palästina News" : "Aktuellstes Palästina News"},
      </h5>
      <h5 class="text-xl md:text-2xl  font-semibold text-black font-poppins mb-0">Nr. ${
        newsData.number
      }</h5>
      <h5 class="text-md md:text-lg  font-medium text-black font-poppins mb-0">${
        isEnglish
          ? date.toLocaleDateString("en-EN", { month: "long" })
          : date.toLocaleDateString("de-DE", { month: "long" }) + ", " + year
      }</h5>
    </div>
    </a>
    `;
}
